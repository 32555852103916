<template>
    <svg
        :style="`color: ${color}`"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M10 10C12.2091 10 14 8.20914 14 6C14 3.79086 12.2091 2 10 2C7.79086 2 6 3.79086 6 6C6 8.20914 7.79086 10 10 10Z"
            fill="currentColor"
        />
        <path
            d="M9.99998 11.3333C5.49998 11.3333 3.33331 14.1413 3.33331 15.7773V16.6666C3.33331 17.0203 3.47379 17.3594 3.72384 17.6095C3.97389 17.8595 4.31302 18 4.66665 18H15.3333C15.6869 18 16.0261 17.8595 16.2761 17.6095C16.5262 17.3594 16.6666 17.0203 16.6666 16.6666V15.7773C16.6666 14.1413 14.5 11.3333 9.99998 11.3333Z"
            fill="currentColor"
        />
    </svg>
</template>

<script>
import color from '@mixin/color'
export default {
    mixins: [color],
}
</script>